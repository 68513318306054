import React, { useMemo, FunctionComponent } from 'react';
import ReactSelect, { StylesConfig, Props as ReactSelectProps, components } from 'react-select';
import CreateableSelect from 'react-select/creatable';
import { get } from 'lodash';
import { withFormGroup, WithFormGroupProps } from './utils';
import { FieldRenderProps } from 'react-final-form';
import colors from 'public/colorsV2.json';
import { SelectOption } from '@shared/types';

export const defaultStyles: StylesConfig = {
  container: (provided, state) => ({
    ...provided,
    width: '100%',
    ...get(state.selectProps, 'styleOverrides.container', {})
  }),
  clearIndicator: (provided, state) => {
    const hasValue = (state.getValue()[0] as SelectOption<string>)?.value !== '';
    return {
      ...provided,
      padding: '4px',
      display: hasValue ? 'flex' : 'none',
      ...get(state.selectProps, 'styleOverrides.clearIndicator', {})
    };
  },
  control: (provided, state) => ({
    ...provided,
    borderColor: colors.grayDark.color,
    borderRadius: 0,
    ...get(state.selectProps, 'styleOverrides.control', {})
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    display: 'none',
    ...get(state.selectProps, 'styleOverrides.dropdownIndicator', {})
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    ...get(state.selectProps, 'styleOverrides.indicatorsContainer', {})
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
    ...get(state.selectProps, 'styleOverrides.indicatorSeparator', {})
  }),
  input: (provided, state) => ({
    ...provided,
    margin: 0,
    padding: 0,
    ...get(state.selectProps, 'styleOverrides.input', {})
  }),
  multiValue: (provided, state) => ({
    ...provided,
    ...get(state.selectProps, 'styleOverrides.multiValue', {})
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    ...get(state.selectProps, 'styleOverrides.multiValueRemove', {})
  }),
  placeholder: (provided, state) => ({
    ...provided,
    ...get(state.selectProps, 'styleOverrides.placeholder', {})
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: 0,
    border: `1px solid ${colors.grayDark.color}`,
    borderRadius: 0,
    ...get(state.selectProps, 'styleOverrides.menu', {})
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
    ...get(state.selectProps, 'styleOverrides.menuList', {})
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    backgroundColor: (() => {
      if (state.isSelected) return colors.grayDark.color;
      if (state.isFocused) return colors.grayLight.color;
      return 'transparent';
    })(),
    color: (() => {
      if (state.isSelected) return colors.white.color;
      return colors.grayDark.color;
    })(),
    ...get(state.selectProps, 'styleOverrides.option', {})
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0.25rem',
    ...get(state.selectProps, 'styleOverrides.option', {})
  })
};

export interface ReactSelectFieldProps
  extends FieldRenderProps<string, HTMLSelectElement>,
    ReactSelectProps {
  asFormGroup?: boolean;
  onClear?: () => void;
  creatable?: boolean;
}

export const ReactSelectField = ({
  input,
  styles = {},
  asFormGroup = false,
  onClear,
  creatable,
  ...props
}: ReactSelectFieldProps): JSX.Element | FunctionComponent<WithFormGroupProps> => {
  const Component = creatable ? CreateableSelect : ReactSelect;

  const ClearIndicator = useMemo(() => {
    return p => {
      const customProps = {
        ...p,
        innerProps: {
          ...p.innerProps,
          onMouseDown: () => {
            p.clearValue();
            if (onClear) onClear();
          }
        }
      };

      return <components.ClearIndicator {...customProps} />;
    };
  }, [onClear]);

  const formFieldBase = useMemo(() => {
    return (
      <Component
        {...props}
        styles={{
          ...defaultStyles,
          ...styles
        }}
        components={{
          ClearIndicator
        }}
        value={input.value}
        onChange={input.onChange}
      />
    );
  }, [ClearIndicator, Component, input.onChange, input.value, props, styles]);

  return useMemo(() => {
    if (!asFormGroup) return formFieldBase;

    return withFormGroup(formFieldBase);
  }, [formFieldBase, asFormGroup]);
};

import { SessionService } from '@shared/modules';
import { useEffect, useMemo, useState } from 'react';
import { downloadBlob } from './download';

interface DownloadRequest {
  url: string;
  saveAsFilename?: string;
}

interface UseDownloaderInterface {
  download: (request: DownloadRequest) => void;
  downloading: boolean;
  error?: string;
  responseStatus?: number;
}

export const useDownloader = (): UseDownloaderInterface => {
  const [downloadRequest, setDownloadRequest] = useState<DownloadRequest | undefined>(undefined);
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [responseStatus, setResponseStatus] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (downloadRequest) {
      setDownloading(true);
      setError(undefined);

      (async () => {
        try {
          const token = SessionService.getToken();
          const response = await fetch(downloadRequest.url, {
            method: 'GET',
            headers: { Authorization: `Bearer ${token}` }
          });
          setResponseStatus(response.status);
          if (!response.ok) throw new Error(`Request failed due to: ${response.statusText}`);
          const blob = await response.blob();
          const [, filename] =
            response.headers.get('Content-Disposition')?.match(/filename="(.+?)"/) ?? [];
          downloadBlob(blob, downloadRequest?.saveAsFilename ?? filename);
        } catch (error) {
          setError(error + '');
        }
        setDownloading(false);
      })();
    }
  }, [downloadRequest]);

  return useMemo(
    () => ({
      download: setDownloadRequest,
      downloading,
      error,
      responseStatus
    }),
    [downloading, error, responseStatus]
  );
};
